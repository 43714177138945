<template>
    <div class="widget">
        <p class="widgetTitlte">Members</p>
        <hr>
    </div>
</template>

<script>


export default {
    
}
</script>

<style lang="scss" scoped>
@import "/src/assets/css/var.scss";

.widget {
    background-color: $back2;
    border-radius: 6px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
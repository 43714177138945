<template>
    <div class="login_form">
        <div class="title">
            <p>Authorisation</p>
        </div>
        <div class="form" @submit.prevent="submit">
            <form action="" name="login_form" class="form">
                <p>
                    <input
                        class="field form-control custom_input"
                        id="login"
                        name="login"
                        placeholder="Login"
                        required=""
                        type="text"
                        v-model="form.login"
                    />
                </p>
                <p>
                    <input
                        class="field form-control custom_input"
                        id="password"
                        name="password"
                        placeholder="Password"
                        required=""
                        type="password"
                        v-model="form.password"
                    />
                </p>
                <p>
                    <input
                        id="remember_me"
                        name="remember_me"
                        type="checkbox"
                        v-model="form.remember_me"
                    />
                    <label for="remember_me">Remember me</label>
                </p>
                <p>
                    <input
                        class="btn btn-submit custom_input"
                        id="submit"
                        name="submit"
                        type="submit"
                        value="Enter"
                    />
                </p>
                <p class="form_error" v-if="form_error.length != 0">
                    {{ form_error }}
                </p>
            </form>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import router from "@/router";
import useLoginState from "@/store/LoginState";
export default {
    setup() {
        const { UserInfo, Settings, updateLoginState } = useLoginState();
        return {
            UserInfo,
            Settings,
            updateLoginState,
        };
    },
    data() {
        return {
            form: {
                login: "",
                password: "",
                remember_me: false,
            },
            form_error: "",
        };
    },
    methods: {
        async submit() {
            let ResData = await api.profile_api.auth_api.login(
                this.form.login,
                this.form.password,
                this.form.remember_me
            );
            if (ResData.status == 200) {
                await this.updateLoginState();
                router.push("/");
            } else {
                if (ResData.status == 401) {
                    this.form_error = "Invalid credentials";
                }
            }
        },
    },
};
</script>

<style scoped>
p {
    margin: 0;
}
.title {
    margin-bottom: 20px;
}
.login_form {
    width: max-content;
    height: max-content;
    border-radius: 6px;
    padding: 20px;
    background-color: #11161d;
}

.login_form > .title > p {
    text-align: center;
    font-size: 25px;
}

#submit {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.error-message {
    font-size: 11px;
}

.error {
    border: solid 1px #a30000 !important;
}

input.error {
    border: solid 1px #da0000;
}

.form_error {
    color: #da0000;
    font-size: 12px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
</style>
